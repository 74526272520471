import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { RobotDateRangeInterface } from "src/app/shared/interfaces/date-range.interface";
import { LogsFiltersStateInterface } from "./logs-filters-state.interface";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export abstract class FiltersStoreService {
  state$ = new BehaviorSubject<LogsFiltersStateInterface>({
    device: '',
    start: null,
    end: null,
    categories: null,
    robotTime: null,
    search: null,
    limit: null,
    page: 1,
    logCodes: null,
    axes: null,
    categoriesDevChecked: false,
    programsType: 'production',
    programNames: [],
  });

  getState$(ignoreMissingDates?: boolean): Observable<LogsFiltersStateInterface> {
    const state$ = this.state$.asObservable();
    return ignoreMissingDates ? state$.pipe(
      filter(state => {
        if (!state.start || !state.end) {
          console.warn("State ignored due to missing dates!");
          return false;
        }
        return true;
      }),
    ) : state$;
  }

  getState(): LogsFiltersStateInterface {
    return this.state$.value;
  }

  refresh(): void {
    this.state$.next({
      ...this.state$.value,
    });
  }

  updateDevice(deviceId: string): void {
    this.state$.next({
      ...this.state$.value,
      device: deviceId,
    });
  }

  updateDateRange(dateRange: RobotDateRangeInterface): void {
    this.state$.next({
      ...this.state$.value,
      start: dateRange.dateFrom.toISOString(),
      end: dateRange.dateTo.toISOString(),
      robotTime: dateRange.robotTime,
      showMissingTime: dateRange.showMissingTime,
    });
  }

  updateLimit(limit: number): void {
    this.state$.next({
      ...this.state$.value,
      limit,
    });
  }

  updateCategories(categories: LogsFiltersStateInterface["categories"]): void {
    this.state$.next({
      ...this.state$.value,
      categories,
    });
  }

  updateProgramNames(programNames: string[]): void {
    const old = this.state$.value.programNames;
    if (old.length === programNames.length && old.every((val, idx) => programNames[idx] === val)) {
      return;
    }
    this.state$.next({
      ...this.state$.value,
      programNames,
    });
  }

  updateProgramsType(programsType: string): void {
    if (this.state$.value.programsType === programsType) return;
    this.state$.next({
      ...this.state$.value,
      programsType,
    });
  }

  updateLogCodes(logCodes?: string | string[] | null): void {
    if (typeof logCodes === 'string') {
      logCodes = [logCodes];
    } else if (!logCodes) {
      logCodes = null;
    }
    this.state$.next({
      ...this.state$.value,
      logCodes,
    });
  }

  updateAxes(axes?: string[] | string | null): void {
    if (!Array.isArray(axes)) {
      axes = axes ? [axes] : null;
    }
    this.state$.next({
      ...this.state$.value,
      axes,
    });
  }

  updateDev(categoriesDevChecked: boolean): void {
    this.state$.next({
      ...this.state$.value,
      categoriesDevChecked,
    })
  }

  update(partialState: Partial<LogsFiltersStateInterface>): void {
    if (Object.keys(partialState).length === 0) return;
    const oldState = this.state$.value;

    if (Object.values(partialState).some((v: unknown) => v === undefined)) {
      throw new Error("Undefined value in partialState");
    }
    if (!Object.keys(partialState).every(k => k in oldState)) {
      throw new Error("Unknown key in partialState");
    }

    this.state$.next({
      ...oldState,
      ...partialState,
    });
  }

  resetCategories(): void {
    this.state$.next({
      ...this.state$.value,
      categories: [],
    });
  }

  updateSearch(search: string): void {
    this.state$.next({
      ...this.state$.value,
      search,
    });
  }
}
