export enum WarningLevelKawasakiEnum {
  MAJOR_FAILURE = 0,
  MINOR_FAILURE = 1,
  WARNING = 2,
  OPERATION_LOG = 3,
}

export enum WarningLevelURInvalidUseInKawasakiEnum {
  WARNING = 2,
  VIOLATION = 3,
  FAULT = 4,
}

