import { Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { UserConfigTypeEnum } from 'src/app/shared/enums/user-config-type.enum';
import { DictionaryInterface } from 'src/app/shared/interfaces/dictionary.interface';
import { UserConfigInterface } from 'src/app/shared/interfaces/user-config.interface';
import { ApiResourceUserConfigService } from 'src/app/shared/modules/api/services/api-resource-user-config/api-resource-user-config.service';
import { LogsCategoriesServiceInterface } from './interfaces/logs-categories-service.interface';

@Injectable()
export abstract class AbstractFiltersService<
  T extends string | number = string | number,
> {
  protected configId: string;
  protected configLabel: string;

  protected abstract cast(val: string): T;
  protected abstract getDefaultConfig(): Omit<UserConfigInterface<T[]>, 'id'>;

  protected constructor(
    protected readonly configType: UserConfigTypeEnum,
    protected readonly apiResourceUserConfigService: ApiResourceUserConfigService,
    protected readonly logsCategoriesService: LogsCategoriesServiceInterface<T>,
  ) {
    this.configLabel = `${configType}0`;
  }

  saveConfig$(categoriesFormValue: DictionaryInterface<boolean>): Observable<any> {
    const categoriesSelected: T[] = Object.entries(categoriesFormValue).reduce((acc: T[], curr: [string, boolean]) => {
      if (curr[1]) {
        acc.push(this.cast(curr[0]));
      }

      return acc;
    }, []);
    const config: UserConfigInterface<T[]> = {
      config_type: this.configType,
      label: this.configLabel,
      config: categoriesSelected,
    };

    return this.apiResourceUserConfigService.updateConfig$(
      this.configId,
      config,
    );
  }

  getConfig$(): Observable<UserConfigInterface<T[]>> {
    // @ts-ignore
    return this.apiResourceUserConfigService.getConfigs$(this.configType).pipe(
      switchMap((configs: Omit<UserConfigInterface<T[]>, 'config'>[]) => {
        const configsExist: boolean = configs.length > 0;

        if (configsExist) {
          this.configId = configs[0].id as string;
          this.configLabel = configs[0].label;

          return this.apiResourceUserConfigService.getConfig$<T[]>(this.configId);
        } else {
          return this.apiResourceUserConfigService.createConfig$<T[]>(this.getDefaultConfig()).pipe(
            tap((userConfig: UserConfigInterface) => {
              this.configId = userConfig.id as string;
              this.configLabel = userConfig.label;
            }),
          );
        }
      }),
    );
  }

  isAnyCategoryChecked(categoriesFormGroupValue: DictionaryInterface<boolean | null | undefined>): boolean {
    return Object.entries(categoriesFormGroupValue).some(([categoryCode, isCategoryChecked]) => {
      const isCategory: boolean = this.logsCategoriesService.getCategories().includes(this.cast(categoryCode));
      return isCategory && !!isCategoryChecked;
    });
  }
}
