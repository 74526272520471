import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { UserConfigTypeEnum } from '../../../../../../shared/enums/user-config-type.enum';
import { WarningLevelURInvalidUseInKawasakiEnum } from "../../../../../../shared/enums/warning-level-kawasaki.enum";
import { RobotDateRangeInterface } from "../../../../../../shared/interfaces/date-range.interface";
import { UserConfigInterface } from '../../../../../../shared/interfaces/user-config.interface';
import { ApiResourceLogsKawasakiService } from '../../../../../../shared/modules/api/services/api-resource-logs/api-resource-logs-kawasaki/api-resource-logs-kawasaki.service';
import { ApiResourceUserConfigService } from '../../../../../../shared/modules/api/services/api-resource-user-config/api-resource-user-config.service';
import { AbstractFiltersService } from '../../../../../diagnostic/logs/abstract-filters-service.service';
import { LogsKawasakiCategoryInterface } from '../../../interfaces/logs-category.interface';
import { LogsKawasakiCategoriesService } from '../../services/logs-kawasaki-categories/logs-kawasaki-categories.service';

@Injectable({
  providedIn: 'root',
})
export class LogsKawasakiFiltersService extends AbstractFiltersService<number> {
  constructor(
    protected apiResourceLogsService: ApiResourceLogsKawasakiService,
    apiResourceUserConfigService: ApiResourceUserConfigService,
    logsCategoriesService: LogsKawasakiCategoriesService,
  ) {
    super(
      UserConfigTypeEnum.LOGS_KAWASAKI_PAGE_CATEGORIES,
      apiResourceUserConfigService,
      logsCategoriesService,
    );
  }

  getFiltersCategories$(
    deviceId: string,
    dateRange: RobotDateRangeInterface,
  ): Observable<LogsKawasakiCategoryInterface[]> {
    return this.apiResourceLogsService.getLogsCategories$(deviceId, dateRange);
  }

  protected cast(val: string): number {
    return Number(val);
  }

  protected getDefaultConfig(): Omit<UserConfigInterface<number[]>, 'id'> {
    return {
      config_type: this.configType,
      label: this.configLabel,
      // TODO: Why does it use UR constants instead of the Kawasaki ones?
      // (I've moved it to Kawasaki constants file, to separate it from UR)
      config: [
        WarningLevelURInvalidUseInKawasakiEnum.FAULT,
        WarningLevelURInvalidUseInKawasakiEnum.VIOLATION,
        WarningLevelURInvalidUseInKawasakiEnum.WARNING,
      ],
    };
  }
}
